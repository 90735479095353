/** Top ProgressBar Options */
export const topProgressBarProps = {
  color: '#EF7D00',
  startPosition: 0.3,
  stopDelayMs: 200,
  height: 3,
  showOnShallow: true,
  options: { showSpinner: false }
}

/** Layout Types */
export const layoutTypes = {
  global: 'global',
  minimal: 'minimal',
  none: 'none'
}

export const ImageConfigurations = {
  url: 'https://test-irs-images.nzsafetyblackwoods.co.nz/process_resize_',
  environment: 'nzspm-test'
}

/** Tab Menu Items for Terms pages */
export const tabItems = [
  { id: 1, tabTitle: 'PRIVACY POLICY', path: './privacy-policy' },
  { id: 2, tabTitle: 'TERMS & CONDITIONS', path: './terms-and-conditions' },
  { id: 3, tabTitle: 'DISCLAIMER', path: './disclaimer' },
  { id: 4, tabTitle: 'RETURNS POLICY', path: './return-policy' },
  { id: 5, tabTitle: 'SHIPPING DETAILS', path: './shipping-details' }
]

/** Tab Menu Items for Support Page */
export const supportTabItems = [
  { id: 1, tabTitle: 'WELCOME', path: './welcome' },
  { id: 2, tabTitle: 'AVAILABILITY', path: './availability' },
  { id: 3, tabTitle: 'CONTRACTED ITEMS', path: './contracted-items' },
  { id: 4, tabTitle: 'COMING SOON', path: './coming-soon' }
]

export const MY_ACCOUNT_TABS = [
  {
    id: 1,
    tabTitle: 'ORDERING',
    subChildren: [
      {
        id: 1,
        title: 'My Catalogue',
        path: '/my-account/ordering/my-catalogue',
        permissionName: 'MY_CATALOGUE'
      },
      {
        id: 2,
        title: 'My Favourites',
        path: '/my-account/ordering/my-favourites',
        permissionName: 'MY_FAVOURITE'
      },
      {
        id: 3,
        title: 'My Order Pad',
        path: '/my-account/ordering/my-orderpad',
        permissionName: 'MY_ORDER_PAD'
      },
      {
        id: 4,
        title: 'My Orders',
        path: '/my-account/my-orders',
        permissionName: 'MY_ORDERS'
      },
      {
        id: 5,
        title: 'Multi Site Ordering',
        path: '/coming-soon',
        permissionName: 'MULTI_SITE_ORDERING'
      }
    ]
  },
  {
    id: 2,
    tabTitle: 'INVOICING & FINANCE',
    subChildren: [
      {
        id: 1,
        title: 'My Spend Limit',
        path: '/coming-soon',
        permissionName: 'MY_SPEND_LIMIT'
      },
      {
        id: 2,
        title: 'Invoice / Statements',
        path: '/coming-soon',
        permissionName: 'INVOICE_AND_STATEMENNT'
      },
      {
        id: 3,
        title: 'My Quotes',
        path: '/coming-soon'
      }
    ]
  },
  {
    id: 3,
    tabTitle: 'ACCOUNT SETTINGS',
    subChildren: [
      {
        id: 1,
        title: 'My Preferences',
        path: '/coming-soon',
        permissionName: 'MY_PREFERENCES'
      },
      {
        id: 2,
        title: 'Change Password',
        permissionName: 'CHANGE_PASSWORD'
      },
      {
        id: 3,
        title: 'Update Details',
        path: '/my-account/account-settings/update-details',
        permissionName: 'UPDATE_DETAILS'
      }
    ]
  }
]

export const MY_ACCOUNT_TABS_PUNCH_OUT = [
  {
    id: 1,
    tabTitle: 'ORDERING',
    subChildren: [
      {
        id: 1,
        title: 'My Catalogue',
        path: '/my-account/ordering/my-catalogue',
        permissionName: 'MY_CATALOGUE'
      },
      {
        id: 2,
        title: 'My Favourites',
        path: '/my-account/ordering/my-favourites',
        permissionName: 'MY_FAVOURITES'
      },
      {
        id: 3,
        title: 'My Order Pad',
        path: '/my-account/ordering/my-orderpad',
        permissionName: 'MY_ORDER_PAD'
      },
      {
        id: 3,
        title: 'My Orders',
        path: '/my-account/ordering/my-orders',
        permissionName: 'MY_ORDERS'
      }
    ]
  }
]

export const protectedURLsForLoggedInUsers = [
  '/auth',
  '/auth/login',
  '/auth/register',
  '/auth/forgotPassword',
  '/auth/resetPassword',
  '/auth/verifyEmail'
]

export const protectedForNonLoggedInUsers = ['/account']

export type BrandPageFilterQuery = {
  '123': string[]
  'A-D': string[]
  'E-I': string[]
  'J-N': string[]
  'O-S': string[]
  'T-Z': string[]
  'all-brands': string[]
}

export const BRAND_PAGE_FILTER_QUERY = {
  'A-D': ['A', 'B', 'C', 'D'],
  'E-I': ['E', 'F', 'G', 'H', 'I'],
  'J-N': ['J', 'K', 'L', 'M', 'N'],
  'O-S': ['O', 'P', 'Q', 'R', 'S'],
  'T-Z': ['T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  '123': ['0-9'],
  'all-brands': [
    'A',
    'a',
    'B',
    'b',
    'C',
    'c',
    'D',
    'd',
    'E',
    'e',
    'F',
    'f',
    'G',
    'g',
    'H',
    'h',
    'I',
    'i',
    'J',
    'j',
    'K',
    'k',
    'L',
    'l',
    'M',
    'm',
    'N',
    'n',
    'O',
    'o',
    'P',
    'p',
    'Q',
    'q',
    'R',
    'r',
    'S',
    's',
    'T',
    't',
    'U',
    'u',
    'V',
    'v',
    'W',
    'w',
    'X',
    'x',
    'Y',
    'y',
    'Z',
    'z'
  ]
}

export const BRAND_PAGE_TOP_FILTER_BUTTONS = [
  {
    label: 'MOST POPULAR',
    value: 'shop-by-brands'
  },
  {
    label: 'ALL BRANDS',
    value: 'all-brands',
    route: '/brands/all-brands'
  },
  {
    label: '123',
    value: '123'
  },
  {
    label: 'A - D',
    value: 'A-D'
  },
  {
    label: 'E - I',
    value: 'E-I'
  },
  {
    label: 'J - N',
    value: 'J-N'
  },
  {
    label: 'O - S',
    value: 'O-S'
  },
  {
    label: 'T - Z',
    value: 'T-Z'
  }
]

/** Sort Function dropdown Options */
export const sortOptions = [
  { label: 'Relevance', value: 'relevanceAsc', sort: 'relevance', sortType: 'asc' },
  { label: 'Price: Low - High', value: 'priceAsc', sort: 'price', sortType: 'asc' },
  { label: 'Price: High - Low', value: 'priceDesc', sort: 'price', sortType: 'desc' },
  { label: 'Name A - Z', value: 'nameAsc', sort: 'name', sortType: 'asc' }
]
