import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  CheckBoxCard,
  ComplexDateRangePicker,
  MultiSelect,
  RangeInput,
  TextField
} from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { changeRouter } from 'lib/utilities'

export interface OrderingFilterSectionProps {
  className?: string
  componentId?: string
  onClickReset: () => void
  userType?: 'guest' | 'webcard' | 'linked'
}

export enum OrderStatus {
  'Processing' = 0,
  'Partial Despatch' = 1,
  'Despatched' = 2,
  'Cancelled' = 3
}

export enum OrderStatusLabels {
  PROCESSING = 'Processing',
  PARTIAL_DESPATCH = 'Partial Despatch',
  DESPATCHED = 'Despatched',
  CANCELLED = 'Cancelled'
}

export enum OrderTypes {
  'Website' = 1,
  'Trade Centre' = 2,
  'Phone / Email' = 3,
  'Mobile App' = 4,
  'EDI' = 5
}

export enum OrderTypesLabels {
  WEBSITE = 'Website',
  TRADE_CENTRE = 'Trade Centre',
  PHONE_EMAIL = 'Phone / Email',
  MOBILE_APP = 'Mobile App',
  EDI = 'EDI'
}

export const OrderingFilterSection: FC<OrderingFilterSectionProps> = ({
  className,
  componentId,
  onClickReset,
  userType
}: OrderingFilterSectionProps) => {
  const OrderingFilterSectionClasses = CN(
    'w-full h-fit flex flex-col p-6 bg-white rounded border border-[#D9D9D5] gap-y-6',
    className
  )
  const params = useSearchParams()
  const router = useRouter()

  const dateRangeRef = useRef<HTMLDivElement>(null)
  const dateRangeContainerRef = useRef<HTMLDivElement>(null)

  const DATE_PICKER_PLACE_HOLDER = ''
  const ONLY_MY_ORDERS = 'only-my-orders'
  const STATUSES = 'statuses'
  const TYPES = 'types'
  const FROM = 'from'
  const TO = 'to'
  const MIN_PRICE = 'minPrice'
  const MAX_PRICE = 'maxPrice'

  const fromParam = params.get(FROM)
  const toParam = params.get(TO)
  const statusesParam = params.get(STATUSES)
  const typesParam = params.get(TYPES)
  const onlyMyOrdersParam = params.get(ONLY_MY_ORDERS)
  const minPriceParam = params.get(MIN_PRICE) ?? undefined
  const maxPriceParam = params.get(MAX_PRICE) ?? undefined

  const [filterDateRange, setFilterDateRange] = useState<any>({
    startDate: null,
    endDate: null,
    key: null
  })
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [filterButtonText, setFilterButtonText] = useState(DATE_PICKER_PLACE_HOLDER)

  useEffect(() => {
    if (fromParam && toParam && filterDateRange.key === null) {
      const fromParts = fromParam?.split('/')
      const toParts = toParam?.split('/')
      setFilterDateRange({
        startDate: new Date(`${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`),
        endDate: new Date(`${toParts[2]}-${toParts[1]}-${toParts[0]}`),
        key: 'selection'
      })
    }
  }, [fromParam, toParam])

  const onDatePickerCloseClick = () => {
    setFilterButtonText(DATE_PICKER_PLACE_HOLDER)
    const url = new URL(window.location.href)
    url.searchParams.delete(FROM)
    url.searchParams.delete(TO)
    router.push(url.toString(), undefined, { shallow: true })
    setFilterDateRange({
      startDate: null,
      endDate: null,
      key: 'selection'
    })
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        dateRangeContainerRef.current &&
        !dateRangeContainerRef.current.contains(event.target as Node)
      ) {
        setOpenDatePicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchend', handleClickOutside)
    }
  }, [dateRangeContainerRef])

  useEffect(() => {
    let text = DATE_PICKER_PLACE_HOLDER

    if (filterDateRange.startDate && filterDateRange.endDate) {
      const { startDate, endDate } = filterDateRange
      const startDateFormatted = startDate.toLocaleDateString('en-GB')
      const endDateFormatted = endDate.toLocaleDateString('en-GB')

      text = `${startDateFormatted} - ${endDateFormatted}`
    }
    setFilterButtonText(text)
  }, [filterDateRange])

  const [checked, setChecked] = useState(onlyMyOrdersParam !== 'false')
  const [minPrice, setMinPrice] = useState<number | undefined>(
    minPriceParam ? Number(minPriceParam) : undefined
  )
  const [maxPrice, setMaxPrice] = useState<number | undefined>(
    maxPriceParam ? Number(maxPriceParam) : undefined
  )
  const [orderStatus, setOrderStatus] = useState(
    statusesParam?.split(',').map(status => ({
      label: status,
      value: OrderStatus[status as keyof typeof OrderStatus]
    }))
  )

  const [orderType, setOrderType] = useState(
    typesParam?.split(',').map(type => ({
      label: type,
      value: OrderTypes[type as keyof typeof OrderTypes]
    }))
  )

  const onResetClick = () => {
    onClickReset()
    setFilterDateRange({
      startDate: null,
      endDate: null,
      key: 'selection'
    })
    setFilterButtonText(DATE_PICKER_PLACE_HOLDER)
    setOrderStatus([])
    setOrderType([])
    setMinPrice(undefined)
    setMaxPrice(undefined)
    setChecked(true)
    changeRouter(router, undefined, undefined, [
      FROM,
      TO,
      STATUSES,
      TYPES,
      ONLY_MY_ORDERS,
      MIN_PRICE,
      MAX_PRICE,
      'page',
      'pageSize',
      'search'
    ])
  }

  return (
    <div className={OrderingFilterSectionClasses}>
      <div className='flex w-full justify-between items-center gap-x-3'>
        <p className='font-700 text-base text-N-700'>Filters</p>
        <Button
          componentId={`${componentId}-filter-section-resetAllFilters`}
          iconBefore='nzsbi-cycle text-[24px]'
          size='md'
          appearance='link-gray'
          onClick={() => {
            onResetClick()
          }}>
          Reset All Filters
        </Button>
      </div>

      <div
        className={CN('grid grid-cols-1 md:!grid-cols-2 gap-4', {
          'lg:!grid-cols-3': userType === 'webcard',
          'lg:!grid-cols-4': userType === 'linked'
        })}>
        <div className='relative w-full' ref={dateRangeContainerRef}>
          <TextField
            label='Order Date'
            value={filterButtonText}
            onClick={() => setOpenDatePicker(prev => !prev)}
            componentId={`${componentId}-date-range-picker`}
            readOnly
            placeholder={'DD/MM/YYYY - DD/MM/YYYY'}
            wrapperClassName={CN('w-full flex justify-center capitalize')}
            iconAfter={
              filterButtonText === DATE_PICKER_PLACE_HOLDER
                ? 'ri-calendar-line'
                : 'nzsbi-close text-[16px]'
            }
            onClickIconAfter={() =>
              filterButtonText === DATE_PICKER_PLACE_HOLDER
                ? setOpenDatePicker(prev => !prev)
                : onDatePickerCloseClick()
            }
          />
          {openDatePicker && (
            <div
              className='absolute bg-white z-[10] left-0 top-[74px] shadow rounded'
              data-component-id={`${componentId}-date-range-picker-popup`}
              ref={dateRangeRef}>
              <ComplexDateRangePicker
                data-component-id={`${componentId}-date-range-picker-popup-container`}
                isRangesDisable={true}
                isFooterButtons={false}
                showDateDisplay={false}
                isResponsive={true}
                onChange={(value: any) => {
                  setFilterDateRange(value)
                  if (value.startDate && value.endDate) {
                    const startDateFormatted = value.startDate.toLocaleDateString('en-GB')
                    const endDateFormatted = value.endDate.toLocaleDateString('en-GB')

                    changeRouter(
                      router,
                      [TO, FROM],
                      [endDateFormatted, startDateFormatted],
                      ['page']
                    )
                  }
                }}
                selectedDate={[filterDateRange.startDate, filterDateRange.endDate]}
              />
            </div>
          )}
        </div>

        {userType === 'linked' && (
          <div className='w-full'>
            <MultiSelect
              className='w-full'
              label='Order Type'
              componentId={`${componentId}-order-type-select`}
              value={orderType}
              options={[
                { label: OrderTypesLabels.WEBSITE, value: OrderTypes.Website },
                { label: OrderTypesLabels.TRADE_CENTRE, value: OrderTypes['Trade Centre'] },
                { label: OrderTypesLabels.PHONE_EMAIL, value: OrderTypes['Phone / Email'] },
                { label: OrderTypesLabels.MOBILE_APP, value: OrderTypes['Mobile App'] },
                { label: OrderTypesLabels.EDI, value: OrderTypes.EDI }
              ]}
              onChange={(types: any) => {
                setOrderType(types)
                if (types?.length > 0) {
                  const typesValues = types.map((type: any) => type.label)
                  changeRouter(router, TYPES, typesValues.join(','), ['page'])
                } else {
                  changeRouter(router, undefined, undefined, [TYPES, 'page'])
                }
              }}
            />
          </div>
        )}
        <div className='w-full'>
          <RangeInput
            minValue={minPrice}
            maxValue={maxPrice}
            componentId={`${componentId}-range-input`}
            onApply={(prices: any) => {
              setMinPrice(prices.minValue)
              setMaxPrice(prices.maxValue)
              changeRouter(
                router,
                [MIN_PRICE, MAX_PRICE],
                [prices.minValue.toString(), prices.maxValue.toString()],
                ['page']
              )
            }}
            onClear={() => {
              setMinPrice(undefined)
              setMaxPrice(undefined)
              changeRouter(router, undefined, undefined, [MIN_PRICE, MAX_PRICE, 'page'])
            }}
            label='Order Amount'
          />
        </div>
        <div className='w-full'>
          <MultiSelect
            className='w-full'
            label='Order Status'
            componentId={`${componentId}-order-status-select`}
            value={orderStatus}
            options={[
              { label: OrderStatusLabels.DESPATCHED, value: OrderStatus.Despatched },
              { label: OrderStatusLabels.PROCESSING, value: OrderStatus.Processing },
              { label: OrderStatusLabels.PARTIAL_DESPATCH, value: OrderStatus['Partial Despatch'] },
              { label: OrderStatusLabels.CANCELLED, value: OrderStatus.Cancelled }
            ]}
            onChange={(statuses: any) => {
              setOrderStatus(statuses)
              if (statuses?.length > 0) {
                const statusValues = statuses.map((status: any) => status.label)
                changeRouter(router, STATUSES, statusValues.join(','), ['page'])
              } else {
                changeRouter(router, undefined, undefined, [STATUSES, 'page'])
              }
            }}
          />
        </div>
        {userType === 'linked' && (
          <div className='w-full'>
            <CheckBoxCard
              componentId={`${componentId}-show-only-myOrders`}
              checked={checked}
              label='Show Only My Orders'
              id={'show-only-myOrders'}
              onChange={e => {
                setChecked(e.target.checked)
                if (e.target.checked) {
                  changeRouter(router, ONLY_MY_ORDERS, 'true', ['page'])
                } else {
                  changeRouter(router, ONLY_MY_ORDERS, 'false', ['page'])
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderingFilterSection
